import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { catchError, map, merge, startWith, of as observableOf, switchMap, EMPTY, takeUntil, debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { Token } from 'src/app/models/user.model';
import { Transaction } from 'src/app/models/transaction.model';
import { TransactionService } from 'src/app/services/transaction.service';
import { AccountService as AccountShared } from 'src/app/shared/account.service';
import { DialogAddComponent } from './dialog-add/dialog-add.component';
import { DialogDeleteComponent } from './dialog-delete/dialog-delete.component';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Account } from 'src/app/models/account.model';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {
  token: Token = new Token;

  dataSource: Transaction[] = [];
  @Input() accounts: Account[] = [];
  transactions: Transaction[] = [];

  displayedColumns: string[] = ['date', 'name', 'amount', 'total'];

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  isLoadingResults = true;
  isRateLimitReached = false;
  resultsLength = 0;

  parentSearch = "";
  @Input() search = "";
  searchFormControl = new FormControl('');

  mobile = false;

  akunTipeActive = '';

  @Output() update = new EventEmitter<string[]>();

  loading = false;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private transactionService: TransactionService,
    private accountShared: AccountShared,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {
    this.token = this.accountShared.getAccount();

    if (window.screen.width < 450) {
      this.mobile = true;
      this.displayedColumns = ['name', 'amount', 'total'];
    }

    if (this.token.email !== 'viewer.mutis@email.com' && this.token.email != 'bc25@email.com') {
      this.displayedColumns.push('action');
    }

    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.loading = true;
    this.searchFormControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(searchText => {
        // this.loading = true;
        this.init();
      });
  }

  init(search: string[] = []): void {
    this.parentSearch = search.length > 0 ? search[0] : this.search;
    if (search.length > 0) this.akunTipeActive = search[1];
    this.search = this.parentSearch;
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.transactionService.getAllTransactions({
            offset: this.paginator.pageIndex,
            keyword: this.search
          }).pipe(catchError(() => observableOf(null)));
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          this.resultsLength = data.total;
          this.transactions = data.data;
          // this.loading = false;
          return this.transactions;
        }),
      )
      .subscribe(data => {
        (this.dataSource = data);
        this.loading = false;
      });
  }

  openDialogAdd(element?: Transaction) {
    const dialogRef = this.dialog.open(DialogAddComponent, {
      minWidth: '50%',
      maxWidth: '90%',
      width: '500px',
      data: {
        search: this.parentSearch,
        transaction: element,
        transactions: this.transactions,
        accounts: this.accounts
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        if (result.isEdit) {
          this.transactionService.editTransaction(result.data).subscribe(x => {
            this.init();
            this.update.emit();
          });
        } else {
          this.transactionService.addTransaction(result.data).subscribe(x => {
            this.init();
            this.update.emit([this.akunTipeActive, result.data.accountName]);
          });
        }
      }
    });
  }

  openDialogDelete(transaction: Transaction) {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: transaction
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.transactionService.deleteTransaction(transaction.id_trx).subscribe(x => {
          this.init();
          this.update.emit();

          this.snackBar.open(x.message, 'Close', {
            duration: 5000,
          });
        });
      }
    });
  }

}

