import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Response } from '../models/response.model';
import { CreateTransaction, Recaps25, Transaction } from '../models/transaction.model';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  token = localStorage.getItem(environment.tokenName);
  httpOptionsAuth = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`
    }),
    params: {}
  };
  ipAddress = '';

  constructor(
    private http: HttpClient
  ) {
    this.http.get("http://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  getNotes() {
    return this.http.get<Response<string[]>>(`${environment.serviceURL}transaction/getNotes`, this.httpOptionsAuth);
  }

  getInvoices() {
    return this.http.get<any[]>(`${environment.serviceURL}transaction/getInvoices`, this.httpOptionsAuth);
  }

  paidInvoice(idInvoice: string) {
    const post = "";
    return this.http.post<any[]>(`${environment.serviceURL}transaction/paidInvoice/${idInvoice}`, post, this.httpOptionsAuth);
  }

  paidFare(idInvoice: string) {
    const post = "";
    return this.http.post<any[]>(`${environment.serviceURL}transaction/paidFare/${idInvoice}`, post, this.httpOptionsAuth);
  }

  getAllTransactions(parameters: any) {
    let params = new HttpParams();
    params = params.append('limit', parameters.limit ?? 10);
    params = params.append('offset', parameters.offset ?? 0);
    params = params.append('keyword', parameters.keyword);

    this.httpOptionsAuth.params = params;

    return this.http.get<Response<Transaction[]>>(`${environment.serviceURL}transaction`, this.httpOptionsAuth);
  }

  getRecaps25(parameters: any) {
    let params = new HttpParams();
    params = params.append('keyword', parameters.keyword);

    this.httpOptionsAuth.params = params;

    return this.http.get<Response<Recaps25[]>>(`${environment.serviceURL}transaction/getRecaps25`, this.httpOptionsAuth);
  }

  addTransaction(createTransaction: CreateTransaction) {
    const data = new Array
    Object.keys(createTransaction).forEach((element, key) => {
      if (Object.values(createTransaction)[key] !== undefined) {
        data.push(element + '=' + Object.values(createTransaction)[key])
      }
    })
    data.push(`ip_address=${this.ipAddress}`);
    const post = data.join('&');

    this.httpOptionsAuth.headers = new HttpHeaders({
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    this.httpOptionsAuth.params = {};

    return this.http.post<Response<Transaction>>(`${environment.serviceURL}transaction`, post, this.httpOptionsAuth);
  }

  editTransaction(createTransaction: CreateTransaction) {
    createTransaction.ip_address = this.ipAddress;

    return this.http.put<Response<Transaction>>(`${environment.serviceURL}transaction/${createTransaction.id_trx}`, createTransaction, this.httpOptionsAuth);
  }

  deleteTransaction(id_trx: number) {
    this.httpOptionsAuth.params = {};

    return this.http.delete<Response<Transaction>>(`${environment.serviceURL}transaction/${id_trx}`, this.httpOptionsAuth);
  }

  getMethod(parameters: any) {
    let params = new HttpParams();
    params = params.append('accountName', parameters.accountName);
    params = params.append('toName', parameters.toName);
    params = params.append('operator', parameters.operator);

    this.httpOptionsAuth.params = params;

    return this.http.get<Response<Transaction[]>>(`${environment.serviceURL}getMethod`, this.httpOptionsAuth);
  }
}
