import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { catchError, merge, startWith, switchMap, of as observableOf, map, takeUntil, debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { Recaps25 } from 'src/app/models/transaction.model';
import { TransactionService } from 'src/app/services/transaction.service';

@Component({
  selector: 'app-recaps',
  templateUrl: './recaps.component.html',
  styleUrls: ['./recaps.component.scss']
})
export class RecapsComponent implements OnInit {
  mobile = false;

  isLoadingResults = true;
  isRateLimitReached = false;

  resultsLength = 0;

  loading = false;

  parentSearch = "";
  @Input() search = "";
  searchFormControl = new FormControl('');

  dataSource: Recaps25[] = [];
  recaps: Recaps25[] = [];

  displayedColumns: string[] = ['name', 'kas'];

  private _unsubscribeAll: Subject<any>;

  constructor(
    private transactionService: TransactionService,
  ) {
    if (window.screen.width < 450) {
      this.mobile = true;
    }

    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.searchFormControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(searchText => {
        // this.loading = true;
        this.init();
      });
  }

  init(search: string[] = []): void {
    this.parentSearch = search.length > 0 ? search[0] : this.search;
    this.search = this.parentSearch;
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.transactionService.getRecaps25({
            keyword: this.search
          }).pipe(catchError(() => observableOf(null)));
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          this.resultsLength = data.total;
          this.recaps = data.data;
          // this.loading = false;
          return this.recaps;
        }),
      )
      .subscribe(data => {
        (this.dataSource = data);
        this.loading = false;
      });
  }

}
