<div *ngIf="token.email !== 'viewer.mutis@email.com' && token.email != 'bc25@email.com'" class="my-2 ms-2">
  <button mat-raised-button color="primary" (click)="openDialogAdd()">Add Record</button>
</div>

<div class="" [ngClass]="mobile ? 'example-container-mobile' : 'example-container'">
  <mat-form-field class="px-2" [ngClass]="mobile ? 'example-95-width' : 'example-half-width'">
    <mat-label>Filter</mat-label>
    <input matInput [(ngModel)]="search" [formControl]="searchFormControl" placeholder="Ex. shopping" #input>
    <button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="search=''">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

  <table mat-table [dataSource]="dataSource">

    <!-- Position Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef> DATE </th>
      <td mat-cell *matCellDef="let element"> 
        {{element.created_at | date:'MMMM d, y'}} 
        <div>{{element.created_at | date:'HH:mm:ss'}}</div>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> ACCOUNT NAME </th>
      <td mat-cell *matCellDef="let element">
        {{element.accountName}} {{element.toName !== '' ? 'to ' + element.toName : ''}}
        <div *ngIf="element.note !== ''"><small>note: {{element.note}}</small></div>

        <div *ngIf="mobile">{{element.created_at | date:'MMMM d, y'}}
          <div>{{element.created_at | date:'HH:mm:ss'}}</div>
        </div>
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> AMOUNT </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'minus': element.operator === 2}"> {{element.amountFormatted}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef> TOTAL </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{'minus': element.total < 0}"> {{element.total}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="action" *ngIf="token.email !== 'viewer.mutis@email.com' && token.email != 'bc25@email.com'">
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let element">
        <button mat-mini-fab color="accent" aria-label="" style="margin-right: 5px;" (click)="openDialogAdd(element)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-mini-fab color="warn" aria-label="" (click)="openDialogDelete(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<mat-paginator [length]="resultsLength" [pageSize]="10" aria-label="Select page of GitHub search results"></mat-paginator>