import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './components/home/home.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { GettokenComponent } from './components/gettoken/gettoken.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TransactionsComponent } from './components/home/transactions/transactions.component';
import { DialogAddComponent } from './components/home/transactions/dialog-add/dialog-add.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './components/home/dashboard/dashboard.component';
import { DialogDeleteComponent } from './components/home/transactions/dialog-delete/dialog-delete.component';
import { AccountsComponent } from './components/home/accounts/accounts.component';
import { DialogEditAccountComponent } from './components/home/accounts/dialog-edit-account/dialog-edit-account.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RecapsComponent } from './components/home/recaps/recaps.component';

const providers: any[] = [
  { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
];

if (environment.baseURL !== 'http://localhost:60431/') {
  providers.push({ provide: LocationStrategy, useClass: HashLocationStrategy });
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    GettokenComponent,
    TransactionsComponent,
    DialogAddComponent,
    DashboardComponent,
    DialogDeleteComponent,
    AccountsComponent,
    DialogEditAccountComponent,
    RecapsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,

    MatTabsModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    MatPaginatorModule,
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatSelectModule,
    MatProgressBarModule,
    MatSnackBarModule,
  ],
  providers: providers,
  bootstrap: [AppComponent],
})
export class AppModule { }
