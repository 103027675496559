<div class="" [ngClass]="mobile ? 'example-container-mobile' : 'example-container'">
  <mat-form-field class="px-2" [ngClass]="mobile ? 'example-95-width' : 'example-half-width'">
    <mat-label>Filter</mat-label>
    <input matInput [(ngModel)]="search" [formControl]="searchFormControl" placeholder="Ex. Doni" #input>
    <button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="search=''">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

  <table mat-table [dataSource]="dataSource">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> NAME </th>
      <td mat-cell *matCellDef="let element">
        <small>{{element.name}}</small>
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="kas">
      <th mat-header-cell *matHeaderCellDef> KAS/EMAS </th>
      <td mat-cell *matCellDef="let element"> 
        <p class="plus" *ngIf="element.hutangKas == 0 && element.hutangEmas == 0 && element.depositKas == 0 && element.depositEmas == 0"><small>LUNAS</small></p>
        <p class="minus" *ngIf="element.hutangKas > 0"><small>hutang Kas: {{element.hutangKasFormatted}}</small></p>
        <p *ngIf="element.depositKas > 0"><small>deposit Kas: {{element.depositKasFormatted}}</small></p>
        <p class="minus" *ngIf="element.hutangEmas > 0"><small>hutang Emas: {{element.hutangEmasFormatted}}</small></p>
        <p *ngIf="element.depositEmas > 0"><small>deposit Emas: {{element.depositEmasFormatted}}</small></p>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<mat-paginator [length]="resultsLength" [pageSize]="10" aria-label="Select page of GitHub search results"></mat-paginator>