<h2 mat-dialog-title>{{isEdit ? "Edit" : "Add"}} Record</h2>
<mat-dialog-content class="mat-typography hide-scroll">
  <p>
    <mat-form-field appearance="fill" class="example-full-width">
      <mat-label>Account Name</mat-label>
      <input type="text" matInput [formControl]="accountFormControl"
        id="account_name"
        name="account_name"
        [matAutocomplete]="autoAccount"
        placeholder="Ex. Cash">
      <mat-autocomplete autoActiveFirstOption #autoAccount="matAutocomplete">
        <mat-option *ngFor="let account of filteredAccounts | async" [value]="account">
          {{account}}
        </mat-option>
      </mat-autocomplete>
      <mat-hint></mat-hint>
      <mat-error *ngIf="accountFormControl.hasError('required')">
        This value is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </p>

  <p>
    <mat-radio-group
      aria-labelledby="radio-group-label"
      class="radio-group"
      [formControl]="operatorFormControl">
      <mat-radio-button class="radio-button" *ngFor="let op of operators" [value]="op" (click)="checkMethod(op)">
        {{op}}
      </mat-radio-button>
    </mat-radio-group>
  </p>

  <p>
    <mat-form-field appearance="fill" class="example-full-width">
      <mat-label>To Name</mat-label>
      <input type="text" matInput [formControl]="toFormControl"
        id="to_name"
        name="to_name"
        [matAutocomplete]="autoTo"
        placeholder="Ex. Cash">
      <button *ngIf="toFormControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="toFormControl.setValue('')">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete autoActiveFirstOption #autoTo="matAutocomplete">
        <mat-option *ngFor="let account of filteredToAccounts | async" [value]="account">
          {{account}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </p>

  <p>
    <mat-form-field appearance="fill" class="example-full-width">
      <mat-label>Amount</mat-label>
      <input type="text" matInput [formControl]="amountFormControl"
        autocomplete="off"
        placeholder="Ex. 10000"
        (keyup)="operationMobile()"
        (keydown)="saveOnEnter($event, true)">
      <button *ngIf="amountFormControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="setZero()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-hint></mat-hint>
      <mat-error *ngIf="amountFormControl.hasError('required')">
        This value is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <button (click)="otherAmount('prev')" mat-mini-fab class="my-fab" aria-label="Example icon button with a menu icon">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button (click)="otherAmount('next')" mat-mini-fab class="my-fab" aria-label="Example icon button with a menu icon">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </p>

  <p>
    <mat-radio-group
      aria-labelledby="radio-group-label"
      class="radio-group"
      [formControl]="toEffectFormControl">
      <mat-radio-button class="radio-button" 
        *ngFor="let op of operators; let i = index" [value]="op"
        [disabled]="radioDisabled[i]"
        (keydown)="saveOnEnter($event)">
        {{op}}
      </mat-radio-button>
    </mat-radio-group>
  </p>

  <p>
    <mat-form-field appearance="fill" class="example-full-width">
      <mat-label>Note</mat-label>
      <input type="text" name="note" matInput [formControl]="noteFormControl"
        [matAutocomplete]="autoNote"
        placeholder="Ex. My Note"
        (keydown)="saveOnEnter($event)">
      <mat-autocomplete autoActiveFirstOption #autoNote="matAutocomplete">
        <mat-option *ngFor="let note of filteredNotes | async" [value]="note">
          {{note}}
        </mat-option>
      </mat-autocomplete>
      <mat-hint></mat-hint>
    </mat-form-field>
  </p>

  <p>
    <mat-form-field appearance="fill" class="example-full-width">
      <mat-label>Choose a date</mat-label>
      <input matInput [matDatepicker]="picker" [formControl]="dateFormControl">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </p>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" 
    [disabled]="formGroup.invalid"
    (click)="save()" cdkFocusInitial>Save</button>
</mat-dialog-actions>