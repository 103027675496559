export class Transaction {
    "id_trx": number;
    "accountName": string;
    "accountType": number;
    "amount": number;
    "amountFormatted": string;
    "created_at": Date;
    "updated_at": Date;
    "id_account": number;
    "id_user": number;
    "note": string;
    "operator": number;
    "toEffect": number;
    "toName": string;
    "toType": number;
    "to_account": number;
    "total": number;
}

export class Recaps25 {
    "name": string;
    "hutangKas": number;
    "hutangKasFormatted": string;
    "hutangEmas": number;
    "hutangEmasFormatted": string;
    "depositKas": number;
    "depositKasFormatted": string;
    "depositEmas": number;
    "depositEmasFormatted": string;
}

export class CreateTransaction {
    "id_trx": number;
    "id_user": number;
    "accountName": string;
    "toName": string;
    "operator": number;
    "toEffect": number;
    "amount": number;
    "note": string;
    "ip_address"?: string;
}